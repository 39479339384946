<template>
  <table-view
    ref="tableView"
    url="/user/black/listForPage"
    :filterable="false"
    :filter-form="dataForm"
    :batch-removable="isAuthed('sys_userblack_delete')"
    delete-url="/user/black/deleteById"
    export-file-name="用户信息表"
    :addable="isAuthed('sys_userblack_add')"
    :editable="isAuthed('sys_userblack_update')"
    :headers="headers"
    show-index
    selectable
    single
  >
    <el-form slot="search" @keyup.enter.native="query">
      <el-input clearable v-model="dataForm.name" placeholder="姓名"></el-input>
      <el-input clearable v-model="dataForm.idCard" placeholder="身份证号码"></el-input>
      <el-button @click="query" type="primary">查询</el-button>
    </el-form>
  </table-view>

</template>

<script>
import TableView from '@/components/templates/table-view'

export default {
  name: 'person-blacklist',

  components: { TableView },

  data () {
    return {
      headers: [
        { id: 1, prop: 'name', label: '姓名', width: 100 },
        { id: 2, prop: 'idCard', label: '身份证号码', width: 200 },
        { id: 3, prop: 'remarks', label: '备注' }
      ],
      dataForm: {
        name: '',
        idCard: ''
      }
    }
  },

  methods: {
    query () {
      this.$refs.tableView.queryData()
    }
  }
}
</script>
